@import "../Colors/colors";
@import "../Mixins/index";

.dialog-container-comment-library .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 75% !important;
  width: 100%;
  height: 90% !important;
  .MuiDialogContent-root {
    padding: 0px !important;
  }

  .MuiTabs-flexContainer button:first-child {
    margin-left: 40px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  @media (max-height: 1500px) {
    .MuiTableContainer-root {
      max-height: 100% !important;
      height: calc(100vh - 168px - 267px) !important;
    }
  }
  @media (max-height: 1200px) {
    .MuiTableContainer-root {
      max-height: 100% !important;
      height: calc(100vh - 150px - 248px) !important;
    }
  }
  @media (max-height: 1000px) {
    .MuiTableContainer-root {
      max-height: 100% !important;
      height: calc(100vh - 150px - 236px) !important;
    }
  }
  @media (max-height: 900px) {
    .MuiTableContainer-root {
      max-height: 100% !important;
      height: calc(100vh - 150px - 230px) !important;
    }
  }
  @media (max-height: 800px) {
    .MuiTableContainer-root {
      max-height: 100% !important;
      height: calc(100vh - 150px - 220px) !important;
    }
  }
  @media (max-height: 700px) {
    .MuiTableContainer-root {
      max-height: 100% !important;
      height: calc(100vh - 100px - 255px) !important;
    }
  }
}

.cl-table.MuiPaper-elevation.MuiPaper-rounded {
  max-width: 100% !important;
  height: 100% !important;
}

.dialog-container-comment-library h2 {
  font-size: 24px !important;
}

.dialog-content-comment-library {
  margin-top: 2px;
  padding-left: 40px !important;
  padding-right: 28px !important;
  padding-bottom: 5px !important;
  .MuiTableContainer-root {
    border: 1px solid $secondary-platinum;
    border-radius: 4px;
  }
  .input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 8px;

    span {
      color: $severity-Electric-Red;
    }
  }

  .comment-input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 6px;
  }

}

.summary-section-copy-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    height: 18px;
    width: 16px;
    color: $Outer-Space !important;
  }
}

.cl_summary_rating_section {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.comment-library-dialog-title {
  padding: 26px 34px 5px 19px !important;
  text-align: left;
  @include text($Dark, 24px, 600, 32px, normal);
}
.comment-library-dialog-title span {
  @include text($Dark, 24px, 600, 32px, normal);
  text-transform: capitalize;
}
.cl-add-comment-sections {
  padding: 12px 0px 0px 0px;
}

.tab_panel_cLibrary .MuiBox-root {
  padding: 0px !important;
}

.tab_panel_cLibrary {
  // min-height: 400px !important;
  // max-height: 600px !important;
}

.comment-library-action {
  padding-left: 34px !important;
  padding-right: 28px !important;
  display: flex !important;
  justify-content: space-between !important;
  height: 64px !important;
  .cl-cancel-btn {
    width: 101px !important;
    height: 34px !important;
    @include text($Spanish-Gray, 13px, 600, 24px, normal);
    border: 1px solid $button-border;
    border-radius: 1px !important;
    text-transform: capitalize !important;
    letter-spacing: 0.5px !important;
  }

  .cl-add-btn {
    width: 170px !important;
    height: 34px !important;
    background: $Tufts-Blue !important;
    @include text($secondary-white, 13px, 600, 24px, normal);
    border: 1px solid $Tufts-Blue;
    border-radius: 1px !important;
    text-transform: unset !important;
    letter-spacing: 0.5px !important;
  }

  .cl-add-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
    border: 1px solid $secondary-Smoke-White;
  }
  .cl-edit-comment-btn {
    width: 157px !important;
    height: 34px !important;
    background: $Tufts-Blue !important;
    @include text($secondary-white, 13px, 600, 24px, normal);
    border: 1px solid $Tufts-Blue;
    border-radius: 1px !important;
    text-transform: unset !important;
    letter-spacing: 0.5px !important;
  }

  .cl-edit-comment-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
    border: 1px solid $secondary-Smoke-White;
  }
}

.cl-sort-icon {
  font-size: 23px !important;
  color: $Santa-Grey !important;
}

.cl-table-head,
.cl-table-head span {
  padding: 18px !important;
  @include text($Outer-Space, 14px, 600, 20px, normal);
}

.cl-table-exp-icon {
  height: 19.5px !important;
  width: 19.5px !important;
  rotate: 90deg;
  color: $Mountain-Mist;
}

.cl-row-status {
  button {
    padding: 0px !important;
  }
}

.cl_row {
  &.Mui-selected {
    background-color: transparent !important;
  }
}

.cl-row-disabled {
  &:hover {
    background-color: transparent !important;
    cursor: default;
  }
}
.MuiButtonBase-root .disabled_collapse_cl_icon {
  cursor: default;
  &:hover {
    background-color: transparent;
  }
}
.cl-search {
  position: relative;

  .MuiOutlinedInput-root {
    padding-left: 26px !important;
    border-radius: 4px !important;
  }

  button {
    padding-right: 0px;
  }

  background-color: $secondary-white;

  & > div:first-child {
    width: 100%;
    max-width: 284px;
  }

  .cl-search-icon {
    position: absolute;
    left: 10px;
    top: 9px;
    cursor: pointer;
    font-size: 18px !important;
  }
}

.cl-search-container {
  padding: 20px 0px 10px 0px !important;
}

.cl-title-col {
  width: 142px !important;
  @include text($Outer-Space, 12px, 400, 20px, normal);
  vertical-align: text-top !important;
}

.cl-comment-col {
  width: 600px !important;
  vertical-align: text-top !important;
  .comment-text {
    @include text($Charcoal-Grey, 14px, 400, 20px, normal);
  }
  p,
  p * {
    @include text($Charcoal-Grey, 14px, 400, 30px, normal);
  }
}

.cl-summary-col {
  width: 280px !important;
  vertical-align: text-top !important;
  .comment-summary {
    @include text($Charcoal-Grey, 14px, 400, 20px, normal);
  }
  p,
  p * {
    font-size: 14px !important;
    @include text($Charcoal-Grey, 14px, 400, 30px, normal);
  }
}

.cl_rating_square_container {
  max-width: 126px !important;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  border-radius: 4px;

  .rating_square {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    flex-shrink: 0;
  }

  svg {
    font-size: 8px;
    color: $secondary-white;
  }

  .cl_rating_name_text {
    @include text($Outer-Space, 12px, 600, 15px, normal);
  }
}

.cl-rating-col {
  min-width: 165px !important;
  vertical-align: text-top !important;
}

.cl-exp-col {
  padding-top: 16px !important;
  vertical-align: text-top !important;
}

.cl-checkbox-col {
  vertical-align: text-top !important;
}

.cl-checkbox-col.cl-check-active {
  border-left: 1px solid $Tufts-Blue;
}

.cl-comment-col,
.cl-summary-colS {
  position: relative;
}

.cl-global-col {
  vertical-align: text-top !important;
  padding: 8px 5px 10px 5px !important;

  svg {
    color: $Sonic-Silver !important;
  }
}
.cl-title-col.row_disabled *,
.cl-comment-col.row_disabled *,
.cl-summary-col.row_disabled *,
.cl-rating-col.row_disabled *,
.cl-global-col.row_disabled *,
.cl-exp-col.row_disabled * {
  color: #b3b3b3 !important;
}

.comment-text {
  @include text($text-Black-Olive, 12px, 400, 20px, normal);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.comment-summary {
  @include text($text-Black-Olive, 14px, 400, 20px, normal);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  p:nth-child(1) {
    display: none;
  }
}

.comment-summary::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.loader-container-comment-library {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 425px !important;
  max-height: 600px;
}

.comment-collapsed-dropdownbox,
.summary-collapsed-dropdownbox {
  height: 27px;
  display: inline-block;
  margin-bottom: -4px;
  padding: 0px 6px !important;
  border: 1px solid #d7d7d7 !important;
  background: #f7f7f7;
  border-radius: 4px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 200px;
  min-width: 100px;
  margin-right: 3px;
  vertical-align: top !important;
}
.comment-summary select,
.comment-text select {
  height: 27px !important;
  appearance: none;
  padding: 2px 6px !important;
  border: 1px solid #d7d7d7 !important;
  background: #f7f7f7;
  border-radius: 4px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 200px;
  min-width: 100px;
  font-size: 14px !important;
}

.comment-text select option,
.comment_detail_cl select option,
.comment-summary select option,
.narrative_summary_content select option,
.narrative_comment select option,
.comment_description_cl select option {
  background-color: #fff !important;
  width: 100px;
  overflow: hidden;
  word-wrap: normal !important;
  white-space: normal;
}

.comment-summary select option:hover,
.comment-text select option:hover {
  background-color: #000 !important;
}

.comment_detail_cl select,
.comment-summary select,
.narrative_summary_content select,
.narrative_comment select,
.comment_description_cl select {
  background: $Cyan-blue-light;
  appearance: none !important;
  height: 28px !important;
  padding: 0px 14px 2px 8px !important;
  border-radius: 4px !important;
  margin: 0px 2px !important;
  border: 1px solid $Tufts-Blue !important;
  background-image: url("../../Images/arrowdown.png");
  background-position: right 4px center !important;
  background-repeat: no-repeat !important;
  background-size: 10px 6px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  max-width: 200px !important;
  min-width: 102px !important;
  font-size: 14px !important;
}

.comment_detail_cl select:disabled,
.comment-summary select:disabled,
.narrative_summary_content select:disabled,
.narrative_comment select:disabled,
.comment_description_cl select:disabled {
  background: $Cyan-blue-light !important;
  border: 1px solid $Tufts-Blue !important;
  color: #999 !important;
  background-image: url("../../Images/disabledArrowDown.png") !important;
  background-repeat: no-repeat !important;
  background-position: right 4px center !important;
  background-size: 10px 6px !important;
}

// .comment_description_cl *,
// .comment_detail_cl *,
// .comment-summary *,
// .narrative_summary_content * {
//   font-size: 16px !important;
//   font-weight: 400 !important;
// }

.comment_library_upload svg {
  margin-right: 10px;
  font-size: 16px;
}

.comment_library_upload {
  border: 1px solid $primary-Sonic-Silver !important;
  color: $primary-Sonic-Silver !important;
  text-transform: none !important;
}

.autocompleteCustom {
  .MuiButtonBase-root:nth-child(1) {
    margin-left: 8px !important;
    height: 24px !important;
    padding: 2px 2px 2px 10px !important;
  }

  .customSearch {
    position: absolute;
    left: 5px;
  }
  .customSearch.hidden {
    display: none !important;
  }

  .MuiCheckbox-root input {
    border-radius: 5px !important;
  }

  .customArrowDown {
    position: absolute;
    right: 12px;
    color: #637083;
    cursor: pointer !important;
    top: 13px !important;
    font-size: 14px !important;
  }
  .customArrowDown.arrowActive {
    rotate: 180deg;
  }

  input:hover {
    cursor: pointer !important;
  }
  .MuiButtonBase-root {
    background-color: $Tufts-Blue;
    opacity: 0.7;
    color: $secondary-white !important;
    height: 24px !important;
    padding-left: 8px !important;
  }
  .MuiButtonBase-root:hover {
    opacity: 0.9;
    cursor: pointer;
    color: $secondary-white !important;
  }
  .MuiChip-deleteIcon {
    color: $secondary-white !important;
    background-color: #07439d !important;
    border-radius: 50%;
    font-size: 16px !important;
    padding: 2px !important;
    margin-right: 4px !important;
  }
  .Mui-checked {
    color: $Tufts-Blue !important;
  }
}

.copyIcon {
  color: $Outer-Space;
}

.autocompleteSummaryCustom {
  .MuiButtonBase-root:nth-child(1) {
    margin-left: 8px !important;
    height: 24px !important;
    padding: 2px 2px 2px 10px !important;
  }

  .customSearch {
    position: absolute;
    left: 5px;
  }
  .customSearch.hidden {
    display: none !important;
  }

  .MuiCheckbox-root input {
    border-radius: 5px !important;
  }

  .customArrowDown {
    position: absolute;
    right: 12px;
    color: #637083;
    cursor: pointer !important;
    top: 13px !important;
    font-size: 14px !important;
  }
  .customArrowDown.arrowActive {
    rotate: 180deg;
  }

  input:hover {
    cursor: pointer !important;
  }
  .MuiButtonBase-root {
    background-color: transparent !important;
    opacity: 0.7;
    color: #637083 !important;
    height: 24px !important;
    padding: 2px 2px 2px 10px !important;
  }
  .MuiButtonBase-root:hover {
    opacity: 0.9;
    cursor: pointer;
    color: #637083 !important;
  }
  .MuiChip-deleteIcon {
    color: $secondary-white !important;
    background-color: #97a1af !important;
    border-radius: 50%;
    font-size: 16px !important;
    padding: 2px !important;
    margin-right: 4px !important;
  }
  .Mui-checked {
    color: $Tufts-Blue !important;
  }
}

.autoHover {
  &:hover {
    cursor: pointer !important;
    background: $CulturedF8;
  }

  input:hover {
    cursor: pointer !important;
  }
}

.add-media-content_for_cl_line_details {
  height: 114px;
  width: 100%;
  max-width: 155px;
  max-height: 114px;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    svg {
      color: $primary-color;
    }
    span {
      @include text($Dark, 14px, 400, 17px, normal);
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.add-cl-media-content {
  height: 114px;
  width: 100%;
  max-height: 114px;
  display: flex;
  align-items: center;
  gap: 40px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    svg {
      color: $primary-color;
    }
    span {
      @include text($Dark, 14px, 400, 17px, normal);
    }
    &:hover {
      cursor: pointer;
    }
    .arrow_right-icon {
      color: $Spanish-Gray !important;
      font-size: 30px !important;
    }
    .media-view-all-text {
      @include text($text-Black-Olive, 14px, 600, 22px, normal);
    }
  }
}

@media only screen and (max-width: 600px) {
  .cl-add-comment-sections {
    padding: 0px !important;
  }
  .dialog-content-comment-library {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
  }

  .dialog-container-comment-library .MuiPaper-elevation.MuiPaper-rounded {
    max-width: 100% !important;
  }

  .comment-library-action {
    flex-direction: column !important;
  }
  .dialog-container-comment-library .MuiPaper-elevation.MuiPaper-rounded {
    .MuiTabs-flexContainer button:first-child {
      margin-left: 10px !important;
      padding-left: 0px !important;
    }
  }
}
// edit comment modal css
.edit_cl_dialog_title {
  @include text($Dark, 24px, 600, 32px, normal);
  padding: 16px !important;
}
.edit_cl_comment_container .MuiPaper-elevation.MuiPaper-rounded {
  .MuiDialogContent-root {
    padding: 32px 40px !important;
  }
}
.edit_cl_comment_container {
  .input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 8px;
    span {
      color: $severity-Electric-Red;
    }
  }
  .comment-input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 6px;
  }
  .sun-editor .se-wrapper {
    min-height: 140px !important;
  }
}
.cl-modal-delete-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  @include text($severity-Electric-Red, 14px, 700, 16px, normal);
  text-decoration-line: underline;
  padding: 0px 20px !important;
}

.checkbox_container {
  height: 19.5px !important;
  width: 19.5px !important;
  & .MuiSvgIcon-root {
    font-size: 20px !important;
  }
}

.comment_tags_container {
  width: 300px !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  gap: 3px !important;
}

.comment_tag {
  height: 18px !important;
  border-radius: 4px !important;
  padding: 3px 8px 0px 8px !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 13.62px !important;
  font-family: Open Sans, sans-serif !important;
  margin-top: 4px !important;
}

.MuiChip-label {
  padding-left: 4px !important;
}
